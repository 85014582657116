.resume {
	line-height: 1.45;
	font-size: 1em;
}

.resume h2 {
	font-size: 1.777em;
	text-decoration: underline;
	text-decoration-color: #C9986F;
}

.resume h3 {
	font-size: 1.333em;
}

.resume h4 {
	font-size: 1.125em;
	color: #666;
}

.resume_container {
	margin: 0 auto;
	width: 78%;
	max-width: 1170px;
	padding: 18px 18px 36px 18px;
	box-shadow: 0 10px 20px 0 rgba(46, 61, 73, 0.15);
}

@media(max-width: 900px){
	.resume_container {
		width: auto;
		padding: 0 18px;
		box-shadow: none;
	}
}

.summary li {
	list-style: none;
	position: relative;
}

.summary li::before {
	content: " ";
	width: 1em;
	height: 1em;
	background: #263F73;
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	left: -30px;
	border-radius: 50%;
	transform: scale(0.35);
	opacity: 0.75;	
}

.skills_row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.skills_row ul {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
	box-sizing: border-box;
	list-style: none;
	margin: 0;
}

@media(max-width: 800px){
	.skills_row ul {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.cert {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;	
}

.cert_description {
	flex: 0 0 50%;
	max-width: 50%;
}

.cert .dates {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}

.experience_job_header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
}

.experience_job_title {
	flex: 0 0 50%;
	max-width: 50%;
}

.experience_date_location {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}

.experience_job_description li {
	list-style: none;
	position: relative;
}

.experience_job_description li::before {
	content: " ";
	width: 1em;
	height: 1em;
	background: #263F73;
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	left: -30px;
	border-radius: 50%;
	transform: scale(0.35);
	opacity: 0.75;
}

.school {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.school_details {
	flex: 0 0 50%;
	max-width: 50%;
}

.school_city_state {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}