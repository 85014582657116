html {font-size: 1.125em;}

body {
  background-color: #fefefe;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #444;
}

p {margin-bottom: 1.3em;}

h1, h2, h3, h4 {
  font-family: 'PT Serif', serif;
  margin: 0.5em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {font-size: 2.369em;}

h3 {font-size: 1.777em;}

h4 {font-size: 1.333em;}

small, .font_small {font-size: 0.75em;}

a,
a:visited {
  text-decoration: underline;
  color: blue;
  color: #3061A5;
}

a:hover,
a:active {
  text-decoration: none;
  color: #263F73;
}