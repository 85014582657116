.contact_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 78%;
	max-width: 1170px;
	margin: 0 auto;
}

@media(max-width: 900px){
	.contact_container {
		width: auto;
		padding: 0 18px;
	}
}

.address_container {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.address_container {
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 0;
		margin-bottom: 18px;
	}
}

.address_container address {
	margin-left: 20px;
	position: relative;
}

.address_container address::before {
	font-family: "FontAwesome";
	content: "\f041";
	position: absolute;
	left: -19px;
	color: #C9986F;
	font-style: normal;
}

.contact_email {
	margin-left: 25px;
	position: relative;
}

.contact_email::before {
	font-family: "FontAwesome";
	content: "\f003";
	position: absolute;
	left: -25px;
	color: #C9986F;
}

.contact_phone {
	margin-left: 25px;
	position: relative;
}

.contact_phone::before {
	font-family: "FontAwesome";
	content: "\f095";
	position: absolute;
	left: -25px;
	color: #C9986F;
}

.form_container {
	flex: 0 0 50%;
	max-width: 50%;
	padding-left: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.form_container {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
	}
}

.form_container label {
	position: relative;
	display: block;
}

.form_container [type="text"],
.form_container [type="email"],
.form_container textarea {
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 0;
	border: none;
	background: #fff;
	padding-left: 10px;
	box-shadow: 0 10px 20px 0 rgba(46, 61, 73, 0.15);
	border-bottom: 2px solid;
	border-color: #C9986F;
	transition: border-color 0.5s ease;
}

.form_container [type="text"]:focus,
.form_container [type="email"]:focus,
.form_container textarea:focus {
	border-color: #3061A5;
}

.form_container [type="text"],
.form_container [type="email"] {
	height: 45px;
	line-height: 45px;
	margin-bottom: 24px;
}

.form_container [type="text"]:focus,
.form_container [type="email"]:focus {
	outline: transparent;
}

.form_container textarea {
	height: 200px;
	padding-top: 12px;
	margin-bottom: 24px;
}

.form_container textarea:focus {
	outline: transparent;
}

.form_container .has-danger [type="text"],
.form_container .has-danger [type="email"],
.form_container .has-danger textarea {
	border-color: #DB222A;
}

.text-help {
	position:absolute;
	top: -25px;
	right: 10px;
	color: #DB222A;
}

.label_text {
	position: absolute;
	left: 10px;
	z-index: 1;
	top: 10px;
	pointer-events: none;
}

.letter {
	display: block;
	float: left;
	transition: all 0.15s ease;
}

.letters-enter-active .letter,
.letters-enter-done .letter {
	transform: translateY(-35px);
	color: #3061A5;
}

.letters-exit .letter {
	transform: translateY(-35px);
	color: #3061A5;
}

.letters-exit-done .letter {
	transform: translateY(0);
	color: #444;
}

.form_container [type="submit"] {
	padding: 12px 0;
	box-sizing: border-box;
	display: inline-block;
	background: #3061A5;
	color: #f7f7f7;
	cursor: pointer;
	width: 100%;
	box-shadow: 0 5px 12px rgba(0,0,0, 0.25);
	border: none;
	transition: background 0.25s ease;
}

.form_container [type="submit"]:hover {
	background: #263F73;
}

.form_submitted-enter-active,
.form_submitted-enter-done {
	filter: blur(5px);
	transition: filter 0.15s ease;
}

.form_submitted-enter-active::after,
.form_submitted-enter-done::after {
 content: "";
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
 height: 100%;
 background: rgba(255,255,255,0.25);
}

.contact_form {
	position: relative;
}

.sub_status {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 18px;
	font-family: 'PT Serif', serif;
	font-size: 1.333em;
}