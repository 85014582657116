html {font-size: 1.125em;}

body {
  background-color: #fefefe;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #444;
}

p {margin-bottom: 1.3em;}

h1, h2, h3, h4 {
  font-family: 'PT Serif', serif;
  margin: 0.5em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {font-size: 2.369em;}

h3 {font-size: 1.777em;}

h4 {font-size: 1.333em;}

small, .font_small {font-size: 0.75em;}

a,
a:visited {
  text-decoration: underline;
  color: blue;
  color: #3061A5;
}

a:hover,
a:active {
  text-decoration: none;
  color: #263F73;
}
.main_nav_container {
    position: relative;
}

.main_nav {

}
@media(max-width: 900px){
    .main_nav.nav_open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 900;
        display: none;
    }
}

@media(max-width: 900px){
    .main_nav.nav_open {
        display: flex;
        overflow-y: auto;
    }
}

.desktop_menu {
    display: block;
    margin: 0;
    padding-left: 0;
}

@media(max-width: 900px){
    .desktop_menu {
        display: none;
    }
}

.mobile_menu {
    display: none;
    margin: 0;
    margin: auto;
    padding-left: 0;
}

.mobile_menu li {
    margin-bottom: 18px;
}

.mobile_menu li:last-child {
    margin-bottom: 0;
}

.main_nav .mobile_menu a {
    color: #f9f9f9;
    text-shadow: 5px 5px 12px #263F73;
    text-decoration: none;
}

@media(max-width: 900px){
    .mobile_menu {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        display: block;
    }

    .main_nav.nav_open .mobile_menu {
        position: relative;
        opacity: 1;
        pointer-events: auto;
        z-index: 200;
    }

    .main_nav.nav_open .mobile_menu li {
        display: block;
        font-size: 2em;
        margin-left: 0;
    }
}

.desktop_menu li {
    display: inline-block;
    margin-left: 20px;
}

.desktop_menu li:first-child {
    margin-left: 0;
}

.main_nav a {
    color: #C9986F;
    -webkit-transition: color 0.25s ease;
    transition: color 0.25s ease;
    text-decoration: none;
}

.main_nav a:hover {
    color: #f9f9f9;
}

.nav_toggle_container {
    position: relative;
    display: flex;
}

.nav_toggle {
    position: relative;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    padding: 18px 15px;
    border: none;
    background: #3061A5;
    border-radius: 50%;
    cursor: pointer;
    z-index: 902;
    display: none;
}

@media(max-width: 900px){
    .nav_toggle {
        display: block;
    }
}

.nav_toggle:focus {
	outline: transparent;
}

.nav_burger {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 24px;
    background: transparent;
}

.nav_burger .line {
    position: absolute;
    left: 0;
    width: 30px;
    height: 3px;
    background: #ffffff;
}

.nav_burger .line:nth-child(1){
	top: 0;
}

.nav_burger .line:nth-child(2){
	top: 10.5px;
}

.nav_burger .line:nth-child(3){
	top: 21px;
}

.radial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 100;
    background: #3061A5;
    display: none;
}

@media(max-width: 900px){
    .radial {
        display: block;
    }
}

@media(max-width: 900px) {
    .nav_open {
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.main_nav.nav_open .mobile_social {
    margin: 0;
    padding: 0;
}

.main_nav.nav_open .mobile_social > li {
    display: inline-block;
    font-size: 1em;
}

.main_nav.nav_open .mobile_social li:first-child {
    margin-right: 24px;
}
.main_header {
	/*background: rgba(50, 100, 200, 0.25);*/
	background: #263F73;
	margin-bottom: 72px;
	padding: 18px 0;
}

@media(max-width: 900px){
	.main_header {
		margin-bottom: 36px;
	}
}

.top_bar {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 78%;
	max-width: 1170px;
	margin: 0 auto;
}

@media(max-width: 900px){
	.top_bar {
		width: auto;
		padding-left: 18px;
		padding-right: 18px;
	}
}

.top_bar h1 {
	margin-top: 0.5em;
	color: #C9986F;
	font-size: 1.777em;
}

.top_bar > a {
	text-decoration: none;
}
.resume {
	line-height: 1.45;
	font-size: 1em;
}

.resume h2 {
	font-size: 1.777em;
	text-decoration: underline;
	-webkit-text-decoration-color: #C9986F;
	        text-decoration-color: #C9986F;
}

.resume h3 {
	font-size: 1.333em;
}

.resume h4 {
	font-size: 1.125em;
	color: #666;
}

.resume_container {
	margin: 0 auto;
	width: 78%;
	max-width: 1170px;
	padding: 18px 18px 36px 18px;
	box-shadow: 0 10px 20px 0 rgba(46, 61, 73, 0.15);
}

@media(max-width: 900px){
	.resume_container {
		width: auto;
		padding: 0 18px;
		box-shadow: none;
	}
}

.summary li {
	list-style: none;
	position: relative;
}

.summary li::before {
	content: " ";
	width: 1em;
	height: 1em;
	background: #263F73;
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	left: -30px;
	border-radius: 50%;
	-webkit-transform: scale(0.35);
	        transform: scale(0.35);
	opacity: 0.75;	
}

.skills_row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.skills_row ul {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
	box-sizing: border-box;
	list-style: none;
	margin: 0;
}

@media(max-width: 800px){
	.skills_row ul {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.cert {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;	
}

.cert_description {
	flex: 0 0 50%;
	max-width: 50%;
}

.cert .dates {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}

.experience_job_header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
}

.experience_job_title {
	flex: 0 0 50%;
	max-width: 50%;
}

.experience_date_location {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}

.experience_job_description li {
	list-style: none;
	position: relative;
}

.experience_job_description li::before {
	content: " ";
	width: 1em;
	height: 1em;
	background: #263F73;
	position: absolute;
	top: 50%;
	margin-top: -0.5em;
	left: -30px;
	border-radius: 50%;
	-webkit-transform: scale(0.35);
	        transform: scale(0.35);
	opacity: 0.75;
}

.school {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.school_details {
	flex: 0 0 50%;
	max-width: 50%;
}

.school_city_state {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}
.contact_container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 78%;
	max-width: 1170px;
	margin: 0 auto;
}

@media(max-width: 900px){
	.contact_container {
		width: auto;
		padding: 0 18px;
	}
}

.address_container {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.address_container {
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 0;
		margin-bottom: 18px;
	}
}

.address_container address {
	margin-left: 20px;
	position: relative;
}

.address_container address::before {
	font-family: "FontAwesome";
	content: "\F041";
	position: absolute;
	left: -19px;
	color: #C9986F;
	font-style: normal;
}

.contact_email {
	margin-left: 25px;
	position: relative;
}

.contact_email::before {
	font-family: "FontAwesome";
	content: "\F003";
	position: absolute;
	left: -25px;
	color: #C9986F;
}

.contact_phone {
	margin-left: 25px;
	position: relative;
}

.contact_phone::before {
	font-family: "FontAwesome";
	content: "\F095";
	position: absolute;
	left: -25px;
	color: #C9986F;
}

.form_container {
	flex: 0 0 50%;
	max-width: 50%;
	padding-left: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.form_container {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
	}
}

.form_container label {
	position: relative;
	display: block;
}

.form_container [type="text"],
.form_container [type="email"],
.form_container textarea {
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 0;
	border: none;
	background: #fff;
	padding-left: 10px;
	box-shadow: 0 10px 20px 0 rgba(46, 61, 73, 0.15);
	border-bottom: 2px solid;
	border-color: #C9986F;
	-webkit-transition: border-color 0.5s ease;
	transition: border-color 0.5s ease;
}

.form_container [type="text"]:focus,
.form_container [type="email"]:focus,
.form_container textarea:focus {
	border-color: #3061A5;
}

.form_container [type="text"],
.form_container [type="email"] {
	height: 45px;
	line-height: 45px;
	margin-bottom: 24px;
}

.form_container [type="text"]:focus,
.form_container [type="email"]:focus {
	outline: transparent;
}

.form_container textarea {
	height: 200px;
	padding-top: 12px;
	margin-bottom: 24px;
}

.form_container textarea:focus {
	outline: transparent;
}

.form_container .has-danger [type="text"],
.form_container .has-danger [type="email"],
.form_container .has-danger textarea {
	border-color: #DB222A;
}

.text-help {
	position:absolute;
	top: -25px;
	right: 10px;
	color: #DB222A;
}

.label_text {
	position: absolute;
	left: 10px;
	z-index: 1;
	top: 10px;
	pointer-events: none;
}

.letter {
	display: block;
	float: left;
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
}

.letters-enter-active .letter,
.letters-enter-done .letter {
	-webkit-transform: translateY(-35px);
	        transform: translateY(-35px);
	color: #3061A5;
}

.letters-exit .letter {
	-webkit-transform: translateY(-35px);
	        transform: translateY(-35px);
	color: #3061A5;
}

.letters-exit-done .letter {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	color: #444;
}

.form_container [type="submit"] {
	padding: 12px 0;
	box-sizing: border-box;
	display: inline-block;
	background: #3061A5;
	color: #f7f7f7;
	cursor: pointer;
	width: 100%;
	box-shadow: 0 5px 12px rgba(0,0,0, 0.25);
	border: none;
	-webkit-transition: background 0.25s ease;
	transition: background 0.25s ease;
}

.form_container [type="submit"]:hover {
	background: #263F73;
}

.form_submitted-enter-active,
.form_submitted-enter-done {
	-webkit-filter: blur(5px);
	        filter: blur(5px);
	-webkit-transition: -webkit-filter 0.15s ease;
	transition: -webkit-filter 0.15s ease;
	transition: filter 0.15s ease;
	transition: filter 0.15s ease, -webkit-filter 0.15s ease;
}

.form_submitted-enter-active::after,
.form_submitted-enter-done::after {
 content: "";
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
 height: 100%;
 background: rgba(255,255,255,0.25);
}

.contact_form {
	position: relative;
}

.sub_status {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 18px;
	font-family: 'PT Serif', serif;
	font-size: 1.333em;
}
.portfolio_item_column {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
	box-sizing: border-box;
	padding-bottom: 18px;
}

.portfolio_item_container {
	/*box-shadow: 0 0 12px rgba(0,0,0, 0.25);*/
	box-shadow: 0 10px 20px 0 rgba(46, 61, 73, 0.15);
	height: 100%;
	background: #fff;
	display: flex;
	flex-direction: column;
}

.portfolio_container .portfolio_item_column:nth-child(3n+1){
	padding-right: 12px;
}

.portfolio_container .portfolio_item_column:nth-child(3n+2){
	padding-left: 6px;
	padding-right: 6px;
}

.portfolio_container .portfolio_item_column:nth-child(3n){
	padding-left: 12px;
}

@media(max-width: 1000px){
	.portfolio_item_column {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.portfolio_container .portfolio_item_column:nth-child(3n+1){
		padding-right: 0;
	}

	.portfolio_container .portfolio_item_column:nth-child(3n+2){
		padding-left: 0;
		padding-right: 0;
	}

	.portfolio_container .portfolio_item_column:nth-child(3n){
		padding-left: 0;
	}

	.portfolio_container .portfolio_item_column:nth-child(2n+1){
		padding-right: 9px;
	}

	.portfolio_container .portfolio_item_column:nth-child(2n){
		padding-left: 9px;
	}
}

@media(max-width: 700px) {
	.portfolio_item_column {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.portfolio_container .portfolio_item_column:nth-child(2n+1){
		padding-right: 0;
	}

	.portfolio_container .portfolio_item_column:nth-child(2n){
		padding-left: 0;
	}	
}

.portfolio_item_container img {
	display: block;
	max-width: 100%;
	min-height: 1px; /** for ie. **/
	cursor: pointer;
	position: relative;
	z-index: 1;
}

.portfolio_item_container p {
	margin-top: 5px;
}

.portfolio_item_container button {
	border: none;
	color: #f7f7f7;
	cursor: pointer;
	padding: 12px 0;
	background: #3061A5;
	box-shadow: 0 5px 12px rgba(0,0,0, 0.25);
	-webkit-transition: background 0.25s ease;
	transition: background 0.25s ease;
}

.portfolio_item_container button:hover {
	background: #263F73;
}

.portfolio_item_container button:focus,
.portfolio_item_container button:active {
	outline: transparent;
}

.portfolio_item_content {
	padding: 0 18px 18px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.portfolio_item_description {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
}
.modal_open {
	overflow: hidden;
}

.portfolio_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	max-width: 1170px;
	width: 78%;
	margin: 0 auto;
}

@media(max-width: 900px){
	.portfolio_container {
		width: auto;
		padding-left: 18px;
		padding-right: 18px;
	}
}

.portfolio_heading {
	max-width: 1170px;
	width: 78%;
	margin: 0 auto;	
}

@media(max-width: 900px){
	.portfolio_heading {
		width: auto;
		padding: 0 18px;
	}
}
.portfolio_modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.75);
	overflow-y: auto;
}

.stuff_container {
	position: relative;
	display: block;
	margin: 0 auto;
	box-sizing: border-box;
	width: 66.66666%;
	max-width: 780px;
	margin-top: 50px;
	margin-bottom: 50px;
}

@media(max-width: 800px){
	.stuff_container {
		width: 100%;
	}
}

.portfolio_modal img {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	-webkit-transform-origin: top right;
	        transform-origin: top right;
}

.modal_content {
	background: #fff;
	box-sizing: border-box;
	padding: 18px;
	-webkit-transform-origin: top;
	        transform-origin: top;
	overflow: hidden;
}

.modal_gallery {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.gallery_item_container {
	flex: 0 0 50%;
	max-width: 50%;
	margin-bottom: 18px;
	box-sizing: border-box;
}

.gallery_item_container:last-child {
	margin-bottom: 0;
}

@media(max-width: 900px){
	.gallery_item_container {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.modal_gallery .gallery_item_container:nth-child(2n+1){
	padding-right: 9px;
}

.modal_gallery .gallery_item_container:nth-child(2n){
	padding-left: 9px;
}

@media(max-width: 900px){
	.modal_gallery .gallery_item_container:nth-child(2n+1){
		padding-right: 0;
	}

	.modal_gallery .gallery_item_container:nth-child(2n){
		padding-left: 0;
	}	
}

.gallery_item_container img {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	position: relative;
}

.modal_links {
	margin: 0;
	padding: 0;
}

.modal_links::after {
	content: " ";
	display: table;
	clear: both;
}

.modal_links li {
	float: left;
	list-style-type: none;
	margin-right: 20px;
}

.modal_links li:last-child {
	margin-right: 0;
}

.modal_links a,
.modal_links a:visited {
	display: block;
	text-align: center;
	width: 50px;
	height: 50px;
	line-height: 50px;
	color: #fff;
	background: #3061A5;
	border-radius: 50%;
	font-size: 1.4em;
	position: relative;
	-webkit-transition: background 0.25s ease;
	transition: background 0.25s ease;
}

.modal_links a:hover {
	background: #263F73;
}

.close_modal {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 0;
	right: -55px;
	cursor: pointer;
	border: none;
	box-shadow: none;
	background: transparent;
}

@media(max-width: 800px){
	.close_modal {
		right: 18px;
		top: -45px;
	}
}

.close_modal .line {
	position: absolute;
	left: 0;
	width: 100%;
	height: 4px;
	background: #fff;
}

.close_modal .line:nth-child(1) {
	top: 20px;
	-webkit-transform: rotateZ(135deg);
	        transform: rotateZ(135deg);
}

.close_modal .line:nth-child(2){
	top: 20px;
	-webkit-transform: rotateZ(45deg);
	        transform: rotateZ(45deg);
}
.intro_container {
	width: 78%;
	max-width: 1170px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 auto 72px auto;
}

@media(max-width: 900px){
	.intro_container {
		width: auto;
		padding: 0 18px;
		margin-bottom: 36px;
	}
}

.intro {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.intro {
		flex: 100% 1;
		max-width: 100%;
		padding-right: 0;
	}
}

.intro_img_container {
	flex: 0 0 50%;
	max-width: 50%;
	padding-left: 9px;
	box-sizing: border-box;
}

@media(max-width: 800px){
	.intro_img_container {
		flex: 100% 1;
		max-width: 100%;
		padding-left: 0;
	}
}

.intro_img_container img {
	width: 400px;
	max-width: 100%;
	border-radius: 50%;
	display: block;
	margin: 0 auto;
}

.work_sample_container {
	width: 78%;
	max-width: 1170px;
	margin: 0 auto;
	margin-bottom: 72px;
}

@media(max-width: 900px){
	.work_sample_container {
		width: auto;
		padding: 0 18px;
		margin-bottom: 36px;
	}
}

.work_sample_row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 36px;
}

.work_sample {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
	box-sizing: border-box;
}

.work_sample_row .work_sample:nth-child(1) {
	padding-right: 12px;
}

.work_sample_row .work_sample:nth-child(2) {
	padding-left: 6px;
	padding-right: 6px;
}

.work_sample_row .work_sample:nth-child(3) {
	padding-left: 12px;
}

@media(max-width: 800px){
	.work_sample {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.work_sample img {
	max-width: 100%;
}

.link_to_portfolio,
.link_to_portfolio:visited {
	position: relative;
	padding: 12px 36px;
	padding-right: 48px;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	background: #3061A5;
	color: #f7f7f7;
	box-shadow: 0 5px 12px rgba(0,0,0, 0.25);
	-webkit-transition: background 0.25s ease;
	transition: background 0.25s ease;
}

.link_to_portfolio:hover {
	background: #263F73;
	color: #f7f7f7;
}

.link_to_portfolio::after {
	font-family: "FontAwesome";
	content: "\F101";
	position: absolute;
	right: 20px;
	-webkit-transition: -webkit-transform 0.25s ease;
	transition: -webkit-transform 0.25s ease;
	transition: transform 0.25s ease;
	transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.link_to_portfolio:hover::after {
	-webkit-transform: translateX(4px);
	        transform: translateX(4px);
}

@media(max-width: 800px){
	.link_to_portfolio {
		display: block;
		text-align: center;
	}
}

.about_seattle {
	position: relative;
	background-size: cover;
	background-position: center 40%;
	padding: 72px 0;
	z-index: 1;
}

.about_seattle::before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.45;
	z-index: -1;
}

.about_contact {
	width: 50%;
	display: block;
	text-align: center;
	margin: 0 auto;
}

.about_contact p {
	color: #fff;
	text-shadow: 2px 2px 0 #333;
}

.link_to_contact,
.link_to_contact:visited {
	padding: 12px 36px;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	background: #3061A5;
	color: #f7f7f7;
	box-shadow: 0 5px 12px rgba(0,0,0, 0.25);
	border: none;
	-webkit-transition: background 0.25s ease;
	transition: background 0.25s ease;
}

.link_to_contact:hover {
	color: #f7f7f7;
	background: #263F73;
}
main {
	position: relative;
}

.pages-exit {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	opacity: 1;
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
}

.pages-exit-active {
	position: absolute;
	-webkit-transform: translateY(50px);
	        transform: translateY(50px);
	opacity: 0;
}
.main_footer {
	padding: 36px 18px;
	text-align: center;
}

.main_footer p {
	margin: 0;
}
