.main_header {
	/*background: rgba(50, 100, 200, 0.25);*/
	background: #263F73;
	margin-bottom: 72px;
	padding: 18px 0;
}

@media(max-width: 900px){
	.main_header {
		margin-bottom: 36px;
	}
}

.top_bar {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 78%;
	max-width: 1170px;
	margin: 0 auto;
}

@media(max-width: 900px){
	.top_bar {
		width: auto;
		padding-left: 18px;
		padding-right: 18px;
	}
}

.top_bar h1 {
	margin-top: 0.5em;
	color: #C9986F;
	font-size: 1.777em;
}

.top_bar > a {
	text-decoration: none;
}